import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// images
import banner_img from "../images/icons/edi.svg";
import online_icon from "../images/attest_online.png";
import auto_icon from "../images/attest_auto.png";
import flow_icon from "../images/attest_flow.png";
import overview_icon from "../images/attest_overview.png";
import edi_img from "../images/edi_swe.svg";

const BOT_CODE = "l8QZLG6U";
const BOT_CODE_DEMO = "WfgujSpL";

function Edi() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_boka_demo, set_chatbot_boka_demo] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>EDI | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Skicka och/eller ta emot EDI-meddelanden." />
                <meta name="keywords" content="edi | EDI-flöde" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Skicka och/eller ta emot EDI-meddelanden." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/edi" />
                <link rel="cannonical" href="https://www.crediflow.se/edi" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">EDI</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Vi erbjuder en helhetslösning för EDI oavsett 
                                        om ni vill skicka eller ta emot EDI-meddelanden.
                                        <br/><br/>
                                        Det kan tex. innebära att ni tar emot EDI order 
                                        och skicka tillbaka ordersvar, 
                                        leveransbesked och faktura
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">edi</span>
                    </div>
                    <div className="grid md:grid-cols-4 gap-8">
                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={online_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">EDI-flöde fullt integrerat i ert system.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={auto_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Automatisera manuell hantering.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={overview_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Enklare översikt över flera bolag.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={flow_icon} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Hantera flera bolag med olika affärssystem i ett och samma flöde.</p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-36 pb-28" id="page_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-start">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Helhetslösning för ert EDI-flöde</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Ingen dubbelregistrering på någon extern webbplats behövs.
                                <br/><br/>
                                Tjänsten fungerar på samma sätt även för 
                                flöden från era leverantörer om ni vill det.
                                <br/><br/>
                                I detta ingår att vi sätter upp EDI-kommunikationen 
                                mot alla de parter som är aktuella för er, 
                                mappning och konvertering samt löpande distribution.
                            </p>
                            <button onClick={() => set_chatbot_boka_demo(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            
                            {chatbot_boka_demo 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE_DEMO} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={edi_img} alt="Skicka e fakura" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Edi;